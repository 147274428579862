import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType, InteractionStatus } from '@azure/msal-browser';
import Home from './pages/Home';
import VideoLibrary from './pages/VideoLibrary';
import Profile from './pages/Profile';
import ContractAnalyzerPage from './pages/ContractAnalyzerPage';
import Header from './components/Header';
import { loginRequest } from './config/authConfig';
import WhitePaper from './pages/WhitePaper';
import ScrollToTop from './components/ScrollToTop';


// Add this for debugging
console.log('API URL from env:', process.env.REACT_APP_API_URL);


const AuthenticationGuard = ({ children }) => {
  const { inProgress, accounts } = useMsal();

  const authRequest = {
    ...loginRequest
  };

  if (inProgress === InteractionStatus.None) {
    if (accounts.length === 0) {
      return <Navigate to="/" />;
    }
  }

  return (
    <MsalAuthenticationTemplate 
      interactionType={InteractionType.Redirect} 
      authenticationRequest={authRequest}
      errorComponent={() => <Navigate to="/" />}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

function App() {
  // Add this for debugging
  React.useEffect(() => {
    console.log('Running in environment:', process.env.NODE_ENV);
    console.log('API URL:', process.env.REACT_APP_API_URL);
  }, []);

  return (
    <Router basename="">
      <ScrollToTop /> {/* Add this line right after Router */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/video-library"
          element={
            <AuthenticationGuard>
              <VideoLibrary />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthenticationGuard>
              <Profile />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/analyze-contract"
          element={
            <AuthenticationGuard>
              <ContractAnalyzerPage />
            </AuthenticationGuard>
          }
        />
        <Route path="/white-paper" element={<WhitePaper />}
        />
        {/* Catch-all route for unknown paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

// Wrap the App component with MsalProvider
const AppWithAuth = ({ instance }) => (
  <MsalProvider instance={instance}>
    <App />
  </MsalProvider>
);

export default AppWithAuth;










































