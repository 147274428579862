import React from 'react';
import styled from 'styled-components';
import {FaRobot, FaClipboardList, FaUserAlt, FaMusic, FaHandsHelping, FaUnlock } from 'react-icons/fa';

// Add any Google Font you prefer, like 'Roboto Mono' or 'Orbitron' for an AI feel.
const SectionContainer = styled.section`
  padding: 50px 20px;
  background: linear-gradient(135deg, #2c3e50, #1a1a1a); /* Dark gradient for a sleek look */
  text-align: center;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700; /* Bold font weight */
  font-family: 'Orbitron', sans-serif; /* AI-inspired font style */
  background: linear-gradient(135deg, #72EDF2, #5151E5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
  letter-spacing: 2px; /* Adds space between letters for a more futuristic look */

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1rem;
  font-family: 'Orbitron', sans-serif;
  background: linear-gradient(135deg, #ffffff, #dddddd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FeatureGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Feature = styled.div`
  flex: 1 1 300px;
  margin: 20px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;


const FeatureTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 10px;
  font-weight: 600;

   @media (max-width: 768px) {
    font-size: 1.3rem;
    text-align: center;
  }
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #5151E5;
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: #72EDF2;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BrandProposition = () => (
  <SectionContainer>
    <SectionTitle>SmartChat</SectionTitle>
    <SectionSubtitle>Powered by AzureAI</SectionSubtitle>
    <FeatureGrid>
      <Feature>
        <IconWrapper>
          <FaRobot />
        </IconWrapper>
        <FeatureTitle>Intelligent Contract Analysis</FeatureTitle>
        <FeatureDescription>
          Leveraging cutting-edge AI to provide in-depth analysis of recording contracts, ensuring you understand every clause and condition.
        </FeatureDescription>
      </Feature>

      <Feature>
        <IconWrapper>
          <FaClipboardList />
        </IconWrapper>
        <FeatureTitle>Transparency and Clarity</FeatureTitle>
        <FeatureDescription>
          Translating complex legal jargon into clear, understandable language, making it easier for you to make informed decisions.
        </FeatureDescription>
      </Feature>

      <Feature>
        <IconWrapper>
          <FaUserAlt />
        </IconWrapper>
        <FeatureTitle>Empowering Independent Artists</FeatureTitle>
        <FeatureDescription>
          Designed specifically for independent hip-hop artists, providing tools and resources tailored to your unique needs.
        </FeatureDescription>
      </Feature>

      <Feature>
        <IconWrapper>
          <FaMusic />
        </IconWrapper>
        <FeatureTitle>Cultural Alignment</FeatureTitle>
        <FeatureDescription>
          Created by industry insiders who understand the nuances of the hip-hop culture and music industry.
        </FeatureDescription>
      </Feature>

      <Feature>
        <IconWrapper>
          <FaHandsHelping />
        </IconWrapper>
        <FeatureTitle>Comprehensive Support</FeatureTitle>
        <FeatureDescription>
          Offering robust support and guidance to help you navigate the complexities of recording contracts and music deals.
        </FeatureDescription>
      </Feature>

      <Feature>
        <IconWrapper>
          <FaUnlock />
        </IconWrapper>
        <FeatureTitle>Accessible and Affordable</FeatureTitle>
        <FeatureDescription>
          Providing an affordable solution for artists at all levels, making professional contract analysis accessible to everyone.
        </FeatureDescription>
      </Feature>
    </FeatureGrid>
  </SectionContainer>
);

export default BrandProposition;




  