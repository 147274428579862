import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    overflow-x: hidden;
    background-color: transparent;
  }

  html {
    max-width: 100vw;
    overflow-x: hidden;
    background-color: transparent;
  }

  #root {
    background-color: transparent;
    min-height: 100vh;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Mobile fixes */
  @media (max-width: 768px) {
    html {
      height: -webkit-fill-available;
    }
    
    body {
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100%;
      overscroll-behavior-y: none;
      -webkit-overflow-scrolling: touch;
    }
    
    #root {
      min-height: 100vh;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    input[type="text"],
    textarea {
      font-size: 16px !important;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      -webkit-appearance: none;
      appearance: none;
      position: relative;
    }
  }
`;

export default GlobalStyle;



