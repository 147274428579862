import React from 'react';
import styled from 'styled-components';
import Hero from '../components/Hero';
import Features from '../components/Features';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import ImageTextSection from '../components/ImageTextSection';
import ImageTextSectionReversed from '../components/ImageTextSectionReversed';
import ImageTextSection3 from '../components/ImageTextSection3';
import TargetCustomer from '../components/TargetCustomer';
import BrandProposition from '../components/BrandProposition';

const MobileContainer = styled.div`
background: #222;
  @media (max-width: 768px) {
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: 768px) {
    padding-top: 0px; /* Add space for fixed header */
    min-height: 100%;
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
`;

const Home = () => {
  return (
    <MobileContainer>
      <ContentWrapper>
        <Hero />
        <Features />
        <AboutUs />
        <ImageTextSection /> 
        <ImageTextSectionReversed />
        <ImageTextSection3 />
        <TargetCustomer />
        <BrandProposition />
        <CTA />
        <Footer />
      </ContentWrapper>
    </MobileContainer>
  );
};

export default Home;





