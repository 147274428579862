import React, { useState } from 'react';
import styled from 'styled-components';
import { FaInstagram, FaFacebook, FaTimes } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 20px 50px;
  background-color: #222;
  color: #fff;
  background-color: transparent;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const IconLink = styled.a`
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s;

  &:hover {
    color: #ff7e5f;
  }
`;

const TermsLink = styled.span`
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s;
  position: relative;
  padding-left: 5px;

  &:hover {
    opacity: 0.8;
    color: #ff7e5f;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  margin: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ff7e5f;
    border-radius: 4px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TermsContent = styled.div`
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ff7e5f;
  }

  h2 {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
    color: #ff7e5f;
    text-align: left;
  }

  h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #ff7e5f;
    text-align: left;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left;
    
  }

  ul, ol {
    margin-left: 20px;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 8px;
    line-height: 1.5;
    text-align: left;
  }

  a {
    color: #ff7e5f;
    text-decoration: none;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    p, li {
      font-size: 14px;
    }
  }
`;

const Footer = () => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  return (
    <FooterContainer>
      <FooterText>
        © {new Date().getFullYear()} SMARTRACTX. All rights reserved.
        <TermsLink onClick={() => setIsTermsOpen(true)}>
          Terms & Conditions
        </TermsLink>
      </FooterText>
      <SocialIcons>
        <IconLink href="https://www.instagram.com/smartractx/" target="_blank" aria-label="Instagram">
          <FaInstagram />
        </IconLink>
        <IconLink href="https://www.facebook.com/profile.php?id=61563351074151" target="_blank" aria-label="Facebook">
          <FaFacebook />
        </IconLink>
      </SocialIcons>

      {isTermsOpen && (
        <ModalOverlay onClick={() => setIsTermsOpen(false)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <CloseButton onClick={() => setIsTermsOpen(false)}>
              <FaTimes />
            </CloseButton>
            
            {/* Add this inside the TermsContent in Footer.js */}
        <TermsContent>
              <h1>SMARTRACTX Terms and Conditions</h1>
              <p style={{ color: '#888', marginBottom: '20px' }}>Last Updated: November 22, 2024</p>

              <h2>1. Overview</h2>
              <p>SMARTRACTX ("we", "our", or "us") provides an AI-powered contract analysis service that helps users understand recording contracts in plain language. By accessing or using SMARTRACTX, you agree to be bound by these Terms and Conditions.</p>

              <h2>2. Service Description</h2>
              <p>SMARTRACTX offers:</p>
              <ul>
                <li>Real-time analysis of recording contracts</li>
                <li>Plain language explanations of contract terms</li>
                <li>Document upload functionality for contracts (.pdf, .doc, .docx, .txt formats)</li>
                <li>Text-based interaction with our AI system</li>
              </ul>

              <h2>3. No Data Storage</h2>
              <h3>3.1. Temporary Processing Only</h3>
              <ul>
                <li>We DO NOT store or retain any uploaded contracts</li>
                <li>We DO NOT store chat histories or conversations</li>
                <li>All document analysis is performed in real-time and temporarily processed</li>
                <li>All data is automatically deleted after each session</li>
              </ul>

              <h3>3.2. User Responsibility</h3>
              <ul>
                <li>Users are responsible for maintaining their own copies of contracts</li>
                <li>Users should not rely on SMARTRACTX to store or retrieve previously analyzed documents</li>
                <li>Each analysis session starts fresh with no historical data</li>
              </ul>

              <h2>4. Privacy and Security</h2>
              <h3>4.1. Authentication</h3>
              <ul>
                <li>Access requires authentication through Microsoft Azure B2C</li>
                <li>User credentials are managed securely through Microsoft's authentication services</li>
              </ul>

              <h3>4.2. Data Transmission</h3>
              <ul>
                <li>All data is transmitted securely using encryption</li>
                <li>We utilize secure HTTPS protocols for all communications</li>
                <li>Uploaded documents are processed in memory and not stored on our servers</li>
              </ul>

              <h2>5. Disclaimers and Limitations</h2>
              <h3>5.1. Not Legal Advice</h3>
              <ul>
                <li>SMARTRACTX provides informational analysis only</li>
                <li>Our AI analysis is not a substitute for legal counsel</li>
                <li>Users should consult qualified legal professionals for legal advice</li>
              </ul>

              <h3>5.2. Accuracy</h3>
              <ul>
                <li>AI models can make mistakes</li>
                <li>Users should verify all important information</li>
                <li>We do not guarantee the accuracy of analysis results</li>
              </ul>

              <h2>6. Acceptable Use</h2>
              <p>Users agree not to:</p>
              <ul>
                <li>Upload contracts containing malicious code</li>
                <li>Attempt to circumvent authentication systems</li>
                <li>Use the service for any illegal purposes</li>
                <li>Share access credentials with unauthorized users</li>
              </ul>

              <h2>7. Intellectual Property</h2>
              <h3>7.1. Your Content</h3>
              <ul>
                <li>Users retain all rights to uploaded contracts</li>
                <li>We do not claim ownership of any uploaded content</li>
              </ul>

              <h3>7.2. Our Service</h3>
              <ul>
                <li>SMARTRACTX's AI analysis system and interface are protected by intellectual property laws</li>
                <li>Users may not copy, modify, or redistribute our service</li>
              </ul>

              <h2>8. Changes to Service</h2>
              <p>We reserve the right to:</p>
              <ul>
                <li>Modify or discontinue features</li>
                <li>Update these terms and conditions</li>
                <li>Change usage limitations or service availability</li>
                <li>Modify the AI analysis system</li>
              </ul>

              <h2>9. Technical Requirements</h2>
              <p>Users must have:</p>
              <ul>
                <li>A modern web browser</li>
                <li>Internet connection</li>
                <li>Supported document formats (.pdf, .doc, .docx, .txt)</li>
                <li>Valid authentication credentials</li>
              </ul>

              <h2>10. Limitation of Liability</h2>
              <p>SMARTRACTX shall not be liable for:</p>
              <ul>
                <li>Decisions made based on AI analysis</li>
                <li>Consequences of non-storage of documents</li>
                <li>System interruptions or unavailability</li>
                <li>Loss of data during transmission</li>
              </ul>

              <h2>11. Contact Information</h2>
              <p>For questions about these terms:</p>
              <p>Technical Support and General Questions: <a href="mailto:hello@gfmservicesco.com" style={{ color: '#ff7e5f' }}>hello@gfmservicesco.com</a></p>

              <h2>12. Consent</h2>
              <p>By using SMARTRACTX, you acknowledge that:</p>
              <ul>
                <li>You have read and understood these terms</li>
                <li>You agree to non-storage of documents and conversations</li>
                <li>You understand the limitations of AI analysis</li>
                <li>You accept these terms and conditions in full</li>
              </ul>
            </TermsContent>
          </ModalContent>
        </ModalOverlay>
      )}
    </FooterContainer>
  );
};

export default Footer;


