import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

const PageContainer = styled.div`
  background: linear-gradient(135deg, #1E2A38, #555555);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 100px auto 50px;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #000000, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #555555;
  margin-bottom: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const SubSection = styled.h3`
  font-size: 1.4rem;
  color: #5151E5;
  margin: 20px 0 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const List = styled.ul`
  margin: 15px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  line-height: 1.5;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const Contact = styled.div`
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const WhitePaper = () => {
  return (
    <PageContainer>
      <ContentWrapper>
        <Title>SMARTRACTX – Empowering Independent Artists Through AI-Driven Contract Analysis</Title>
        
        <Section>
          <SectionTitle>1. Introduction</SectionTitle>
          <Paragraph>
            The music industry has long been recognized for its complexity, particularly when it comes to legal
            agreements. Contracts involving recording deals, publishing rights, and distribution agreements are often
            filled with dense legal jargon, leaving independent artists vulnerable to unfavorable terms. Many rising hip-
            hop artists, especially those without access to legal teams, sign contracts they do not fully understand,
            resulting in disputes, financial losses, or long-term restrictions on their creative freedom.
          </Paragraph>
          <Paragraph>
            SMARTRACTX is an AI-powered contract analysis platform designed to address these issues head-on.
            By leveraging cutting-edge AI technologies such as Natural Language Processing (NLP) and machine
            learning, SMARTRACTX© simplifies contract analysis, translating complex legal language into plain
            terms, and providing artists with actionable insights to protect their rights and maximize their potential.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>2. Industry Context and Challenges</SectionTitle>
          <SubSection>2.1 The Growth of Independent Artists</SubSection>
          <Paragraph>
            The rise of independent music production, self-distribution platforms, and digital marketing has
            democratized the music industry. According to *MIDiA Research*, independent artists generated over $1.2
            billion in revenue in 2020, and the sector continues to grow rapidly. However, with this growth comes the
            need for effective contract management solutions, particularly for artists negotiating their own deals without
            the traditional safety net of record labels or experienced legal teams.
          </Paragraph>

          <SubSection>2.2 Common Contract Pitfalls</SubSection>
          <Paragraph>
            Many artists, eager to secure a deal, rush into contracts without understanding the nuances of legal terms,
            leading to: 
          </Paragraph>
          <List>
            <ListItem>Loss of Ownership: Many artists unknowingly sign away ownership of their masters.</ListItem>
            <ListItem>Unfavorable Royalties: Hidden clauses and complex royalty structures can significantly diminish an
            artist's earnings.</ListItem>
            <ListItem>Long-Term Restrictions: Artists may be locked into restrictive contracts that limit their creative freedom
            or tie them to unfavorable deals for extended periods.</ListItem>
          </List>
        </Section>

        <Section>
          <SectionTitle>3. The Role of AI in Contract Management</SectionTitle>
          <SubSection>3.1 AI-Powered Contract Analysis</SubSection>
          <Paragraph>
            SMARTRACTX© harnesses the power of Natural Language Processing (NLP) to simplify legal jargon,
            making complex contract terms accessible to artists without a legal background. The AI engine scans
            contracts for key terms such as royalty splits, ownership rights, and contract duration, presenting this
            information in clear, plain language. This allows artists to understand exactly what they're signing.
          </Paragraph>

          <SubSection>3.2 Real-Time Insights and Risk Identification</SubSection>
          <Paragraph>
            In addition to simplifying language, SMARTRACTX's AI identifies potential risks, such as:
          </Paragraph>
          <List>
            <ListItem>Unfavorable Clauses: AI flags clauses that deviate from industry norms, enabling artists to renegotiate
            terms.</ListItem>
            <ListItem>Deadline Alerts: Automated reminders for contract deadlines and renewals, ensuring artists don't miss key
            dates.</ListItem>
          </List>

          <SubSection>3.3 Data-Driven Negotiations</SubSection>
          <Paragraph>
            SMARTRACTX© provides users with data-driven insights by comparing their contracts to industry
            benchmarks. If the platform identifies that similar artists are receiving better terms—such as higher royalty
            rates—it advises users to negotiate for more favorable conditions.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>4. Key Features of SMARTRACTX</SectionTitle>
          <SubSection>4.1 Contract Simplification and Translation</SubSection>
          <Paragraph>
            SMARTRACTX's NLP engine translates complex legal terms into plain language, helping artists fully
            understand their rights and obligations. For example, instead of vague language about "perpetual rights,"
            SMARTRACTX explains that it means the artist is giving up rights indefinitely.
          </Paragraph>

          <SubSection>4.2 Secure Document Management</SubSection>
          <Paragraph>
            The platform also offers a secure, cloud-based storage solution where artists can upload, store, and manage
            their contracts. All documents are encrypted to ensure the privacy and security of sensitive legal information.
          </Paragraph>

          <SubSection>4.3 Collaboration Tools</SubSection>
          <Paragraph>
            SMARTRACTX© provides real-time collaboration features, enabling artists to share contracts with
            managers, lawyers, or collaborators. The platform also tracks changes and comments to facilitate smooth
            negotiations.
          </Paragraph>

          <SubSection>4.4 Learning Portal</SubSection>
          <Paragraph>
            To further empower artists, SMARTRACTX© includes a Content Hub with tutorials on topics such as
            contract types, music industry best practices, and how to use the platform effectively. The portal helps
            artists gain a deeper understanding of legal and business issues in the music industry.
          </Paragraph>
        </Section>
        
        <Section>
        <SectionTitle>5. The Benefits of SMARTRACTX</SectionTitle>
          <SubSection>5.1 Empowering Artists</SubSection>
          <Paragraph>
            SMARTRACTX© gives independent artists control over their careers by offering transparency and clarity
            in contract analysis. With simplified legal language and clear insights into contract terms, artists are
            empowered to make informed decisions.
          </Paragraph>

          <SubSection>5.2 Cost-Effective Legal Solutions</SubSection>
          <Paragraph>
            Hiring entertainment lawyers can cost anywhere from $250 to $700 per hour. SMARTRACTX© provides
            many of the same benefits of legal consultation at a fraction of the cost, making it accessible to artists who
            may not have the resources to hire full-time legal help.
          </Paragraph>

          <SubSection>5.3 Scalability for Growth</SubSection>
          <Paragraph>
            As artists grow in their careers, SMARTRACTX© grows with them. Whether handling contracts for
            individual deals or managing multiple agreements across distribution, performance, and merchandising, the
            platform is scalable to meet the needs of both emerging and established artists.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>6. Real-World Impact and Case Studies</SectionTitle>
          <SubSection>6.1 Protecting Artists from Unfavorable Deals</SubSection>
          <Paragraph>
            Several independent hip-hop artists have publicly shared their struggles with unfair contracts. Artists like
            Lil Uzi Vert and Megan Thee Stallion have been vocal about signing deals that negatively impacted their
            careers. SMARTRACTX© addresses these concerns by enabling artists to spot potential red flags early in
            the negotiation process.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>7. The Future of SMARTRACTX and AI in the Music Industry</SectionTitle>
          <Paragraph>
            SMARTRACTX© is just the beginning of how AI will revolutionize contract analysis in the music industry.
            As the platform evolves, it will integrate more advanced machine learning models to predict legal disputes,
            provide real-time updates on industry trends, and even assist in drafting new contracts.
          </Paragraph>
          <Paragraph>
            Looking ahead, SMARTRACTX© aims to expand its reach beyond hip-hop to serve a broader range of
            independent artists, including those in R&B, pop, and rock, all while continuing to enhance its AI
            capabilities to meet the evolving needs of the music industry.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>8. Conclusion</SectionTitle>
          <Paragraph>
            The complexities of contract management in the music industry have historically disadvantaged
            independent artists. With SMARTRACTX©, these challenges can be addressed head-on through the power
            of AI. By offering contract simplification, secure management, real-time insights, and educational resources,
            SMARTRACTX is helping independent artists take control of their careers, protect their creative rights,
            and succeed in a competitive industry.
          </Paragraph>
        </Section>

        <Contact>
          <SubSection>Contact Us</SubSection>
          <Paragraph>
            For more information or to schedule a demo, visit smartractx.com
          </Paragraph>
          <Paragraph>
            References:
          </Paragraph>
          <List>
            <ListItem>1 HotNewHipHop</ListItem>
            <ListItem>2 OkayPlayer</ListItem>
          </List>
        </Contact>
      </ContentWrapper>
      <Footer />
    </PageContainer>
  );
};

export default WhitePaper;